import React, { Component } from "react";
import Lightbox from "./Lightbox";
import * as Widget from "./Widget";
import styled from "styled-components";

const Image = styled.div`
  background-image: url(${props => props.img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fdfdfd;

  width: 440px;
  height: 440px;

  @media screen and (max-width: 480px) {
    width: 300px;
    height: 300px;
  }
`;

class ImageDisplayer extends Component {
  render() {
    let { images = [] } = this.props;
    // images = [{ src: 'xxx' },]
    if (images.length === 0) {
      return (
        <Widget.Col css={"flex: 1; padding: 20px; align-items: center;"}>
          <Image img={""} />
        </Widget.Col>
      );
    }
    return (
      <Widget.Col css={"flex: 1; padding: 20px; align-items: center;"}>
        <Image img={images[0].src} />
        <Lightbox imagesArray={images} />
      </Widget.Col>
    );
  }
}

export default ImageDisplayer;
