import React from "react";
import styled from "styled-components";

const Comment = styled.div`
  margin: 20px;

  h6 {
    color: #ccc;
  }

  p {
  }
`;

const fake_comment = [
  {
    name: "Joyce",
    date: "2018/07/23",
    content: "味道很天然好聞，使用後肌膚很柔嫩舒服，清爽不油膩，值得推薦！"
  },
  {
    name: "Sabrina",
    date: "2017/11/29",
    content: "超好用！"
  },
  {
    name: "Kana",
    date: "2018/02/23",
    content: "很漂亮的產品，氣味也很香，也不油，容易吸收"
  }
];

class ProductCommentList extends React.Component {
  render() {
    return (
      <div>
        {fake_comment.map((i, idx) => (
          <Comment key={idx}>
            <h5>{i.name}</h5>
            <h6>{i.date}</h6>
            <p>{i.content}</p>
          </Comment>
        ))}
      </div>
    );
  }
}

export default ProductCommentList;
