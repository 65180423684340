import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { connect } from "react-redux";
import { withPage } from "../../Page";
import ActionCreator from "../../ActionCreator";
import Selectors from "../../Selectors";
import * as Widget from "../../Components/Widget";
import FilterPanel from "../../Components/FilterPanel";
import { ProductTabs } from "../../Components/ProductDetailPage.comp";
import ProductImages from "../../Components/ProductImages";
import { formatPrice } from "../../Utils/PriceFormatter";
import JsonParser from "../../Utils/JsonParser";
import labels from "../../Domain/CategoryTree";
import AddToCartDialog from "../../Components/AddToCartDialog";

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      item: null,
      showDialog: true
    };
  }

  componentDidMount() {
    let { appActions, productId } = this.props;
    appActions
      .fetchProductById(productId)
      .then(item => {
        this.setState({ item });
      })
      .catch(err => {
        // TODO: handle for 404 and api failure
        console.warn(err);
      });
  }

  render() {
    let { navActions, appActions } = this.props;
    let { item, showDialog } = this.state;

    // the `item.image` returns an url directly, and the `item.images` is an array of objects
    // with property `.image`
    let itemImages =
      item &&
      (item.image ? [{ image: item.image }, ...item.images] : item.images).map(
        image => ({ src: image.image, ...image })
      );

    return (
      <LayoutWrapper>
        <div className="products-filter">
          <FilterPanel
            labels={labels}
            activeFilter={""}
            onFilterItemClick={updateLabel =>
              navActions.push(`/tours/${updateLabel}`)
            }
          />
        </div>

        <Wrapper className="product-detail-content">
          {item ? (
            <>
              <section className="banner">
                <div className="banner-content">
                  <div className="labels">
                    {JsonParser(item.label, []).map(label => (
                      <Link key={label} to={`/tours/${label}`}>
                        {getDisplay(labels[0], label)}
                      </Link>
                    ))}
                  </div>
                  <h2 className="title">{item.name}</h2>
                </div>
              </section>

              <section className="top">
                <div className="top-content">
                  <div className="gallery">
                    <ProductImages images={itemImages} />
                  </div>

                  <div className="summary">
                    <p className="description">{item.description}</p>
                    <h3 className="price">NT {formatPrice(item.price || 0)}</h3>
                    {this._isItemInCart() ? (
                      <button onClick={() => this.removeItemFromCart()}>
                        從購物車移除
                      </button>
                    ) : (
                      <button onClick={() => this._addItemToCart()}>
                        加入購物車
                      </button>
                    )}
                  </div>
                </div>
              </section>

              <ProductTabs item={item} />
            </>
          ) : (
            <Widget.Row css="padding: 20px;" align="center" justify="center">
              <Widget.Spinner />
            </Widget.Row>
          )}
        </Wrapper>
        {showDialog ? (
          <AddToCartDialog close={() => this.setState({ showDialog: false })} />
        ) : null}
      </LayoutWrapper>
    );
  }

  _isItemInCart = () => {
    let { cartData } = this.props;
    let { item } = this.state;

    if (!cartData) {
      return false;
    }
    return !!cartData.items.find(i => i.product.id === item.id);
  };

  removeItemFromCart = () => {
    let { cartData, appActions } = this.props;
    let { item } = this.state;

    let foundIdx = cartData.items.findIndex(i => i.product.id === item.id);
    appActions.removeItemFromCart(foundIdx);
  };

  _addItemToCart = () => {
    let { appActions, navActions } = this.props;
    let { item } = this.state;
    appActions
      .addItemToCart({ product: item.id, quantity: 1 })
      .then(() => navActions.push("/cart"))
      .catch(err => 0);
  };
}

const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  background-color: #e6e6e6;
  flex-direction: row-reverse;

  & > .products-filter {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.3);
  }

  & > .product-detail-content {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    background-color: white;
  }
`;

const Wrapper = styled.div`
  & > section.banner {
    padding: 20px;
    background-color: #eee;

    .banner-content {
      max-width: 1024px;
      margin: 0 auto;

      .labels {
        flex-wrap: wrap;

        a {
          display: inline-block;
          padding: 4px 12px;
          border-radius: 8px;
          background-color: #ccc;
          text-decoration: none;
          margin-right: 10px;
        }

        @media screen and (max-width: 769px) {
          text-align: center;
        }
      }

      h2.title {
        margin-top: 10px;
        @media screen and (max-width: 769px) {
          text-align: center;
        }
      }
    }
  }

  & > section.top {
    /* in case you need to add a background color, you can add it here */
    padding: 20px;

    .top-content {
      max-width: 1024px;
      margin: 0 auto;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .gallery {
        width: 440px;
        height: 440px;
        margin-right: 20px;

        /* 440 + 320 + 20 + 200 */
        @media screen and (max-width: 980px) {
          width: 360px;
          height: 360px;
          margin-right: 0px;
        }

        @media screen and (max-width: 480px) {
          width: 300px;
          height: 300px;
        }
      }

      .summary {
        display: flex;
        flex-direction: column;
        flex: 1;
        min-width: 320px;

        h3.price {
        }

        button {
          padding: 10px;
          background-color: #0075c1;
          color: white;
          margin-top: 10px;
          cursor: pointer;
        }

        button.disabled {
          background-color: grey;
          cursor: not-allowed;
        }

        button:focus {
          outline: none;
        }

        p.description {
          padding: 20px 0px;
          color: #4e4e4e;
        }
      }
    }
  }
`;

function getDisplay(node, target) {
  if (node.name === target) {
    return node.display || node.name;
  }

  if (node.children) {
    for (let subtreeNode of node.children) {
      let display = getDisplay(subtreeNode, target);
      if (display) {
        return display;
      }
    }
  }
}

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: Selectors.getLoginUser(state),
      productId: Selectors.getQueryParams(ownProps).id,
      cartData: Selectors.getCartData(state)
    }),
    ActionCreator
  )(ProductDetailPage)
);
