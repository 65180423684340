import React, { Component } from "react";
import styled from "styled-components";

class TabBar extends Component {
  render() {
    let { activeTab, tabs, onTabClick } = this.props;
    return (
      <Wrapper>
        {tabs.map((t, idx) => (
          <TabItem
            key={idx}
            selected={t.value === activeTab}
            onClick={() => onTabClick(t.value)}
          >
            {t.display}
          </TabItem>
        ))}
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;

  & > .content {
    flex: 1;
    overflow: auto;
    background-color: #ffffff;
    padding: 20px;
    box-shadow: 0 2px 2px 0 rgba(154, 154, 154, 0.5);
  }
`;

const TabItem = styled.button`
  border-left: "1px solid #ccc";
  border-bottom: 0px;
  border-right: "1px solid #ccc";
  border-top: "1px solid #ccc";
  margin: 0px;
  width: 120px;
  height: 50px;
  background-color: ${props => (props.selected ? "#F0F0F0" : "white")};
  font-size: 14px;
  color: #777777;

  :focus {
    outline: 0;
  }
`;

export default TabBar;
