import React, { Component } from "react";
import styled from "styled-components";
import Dialog from "./Dialog";
import * as Widget from "./Widget";
import CartOptionItem from "./CartOptionItem";

const FAKE_DATA = [
  {
    id: 1,
    title: "單人房",
    totalAmount: 1,
    isSelected: false,
    adult: {
      price: 5999,
      amount: 0
    },
    childHasBed: {
      price: 5999,
      amount: 0
    },
    childNoBed: {
      price: 5999,
      amount: 0
    },
    childAddBed: null,
    baby: null
  },
  {
    id: 2,
    title: "雙人房",
    totalAmount: 2,
    isSelected: false,
    adult: {
      price: 4999,
      amount: 0
    },
    childHasBed: {
      price: 4999,
      amount: 0
    },
    childNoBed: {
      price: 4999,
      amount: 0
    },
    childAddBed: null,
    baby: {
      price: 4999,
      amount: 0
    }
  },
  {
    id: 3,
    title: "三人房",
    totalAmount: 3,
    isSelected: false,
    adult: {
      price: 4999,
      amount: 0
    },
    childHasBed: {
      price: 4999,
      amount: 0
    },
    childNoBed: {
      price: 4999,
      amount: 0
    },
    childAddBed: null,
    baby: {
      price: 4999,
      amount: 0
    }
  },
  {
    id: 4,
    title: "四人房",
    totalAmount: 4,
    isSelected: false,
    adult: {
      price: 3999,
      amount: 0
    },
    childHasBed: {
      price: 3999,
      amount: 0
    },
    childNoBed: {
      price: 3999,
      amount: 0
    },
    childAddBed: null,
    baby: {
      price: 3999,
      amount: 0
    }
  }
];

const Modal = styled.div`
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1000;

  & > div {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    width: 800px;
    @media screen and (max-width: 1023px) {
      max-width: 600px;
      margin: 0 12px;
    }

    & > p {
      color: #3f484c;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
`;

const OptionsWrapper = styled.div`
  @media screen and (max-width: 1023px) {
    overflow-y: scroll;
    max-height: 400px;
  }
`;

const SubmitButton = styled.button`
  cursor: pointer;
  width: 100px;
  height: 44px;
  background-color: #f2511b;
  border: 0;
  outline: none;
  color: #fff;
  font-size: 20px;
`;

const CloseButton = styled.button`
  cursor: pointer;
  width: 100px;
  height: 44px;
  background-color: #e1e1e1;
  border: 0;
  outline: none;
  color: #4a4a4a;
  font-size: 20px;
  margin-right: 10px;
`;

class AddToCartDialog extends Component {
  state = {
    options: FAKE_DATA
  };

  render() {
    const { options } = this.state;
    const { close } = this.props;

    return (
      <Modal
        id="add-cart-modal"
        onClick={e => {
          if (e.target.id === "add-cart-modal") {
            close();
          }
        }}
      >
        <div>
          <p>選擇參團人數與參團費用</p>
          <OptionsWrapper>
            {options.map((option, index) => (
              <CartOptionItem
                key={option.id}
                onSelect={() =>
                  this.setState({
                    options: [
                      ...options.slice(0, index),
                      {
                        ...option,
                        isSelected: !option.isSelected
                      },
                      ...options.slice(index + 1)
                    ]
                  })
                }
                onChangeFieldAmount={(field, value) =>
                  this._onChangeFieldAmount(option, index, field, value)
                }
                option={option}
              />
            ))}
          </OptionsWrapper>
          <div
            style={{
              alignSelf: "stretch",
              marginTop: 12,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end"
            }}
          >
            <CloseButton type="button" onClick={() => close()}>
              關閉
            </CloseButton>
            <SubmitButton type="button" onClick={this._submit}>
              加入
            </SubmitButton>
          </div>
        </div>
      </Modal>
    );
  }

  _onChangeFieldAmount = (option, index, field, value) => {
    const { options } = this.state;

    this.setState({
      options: [
        ...options.slice(0, index),
        {
          ...option,
          [field]: {
            ...option[field],
            amount: value
          }
        },
        ...options.slice(index + 1)
      ]
    });
  };

  _checkOption = option => {
    if (option.adult.amount < 1) {
      alert("須至少一位大人");
      return option.adult.amount < 1;
    }

    if (
      option.adult.amount + option.childHasBed.amount !==
      option.totalAmount
    ) {
      alert("房型與人數不符");
      return (
        option.adult.amount + option.childHasBed.amount !== option.totalAmount
      );
    }
  };

  _submit = () => {
    const { options } = this.state;
    const result = options.filter(option => option.isSelected);

    const hasFailOption = !!result.find(option => this._checkOption(option));

    if (!hasFailOption) {
      console.log("result", result); //add to cart
    }
  };
}

export default AddToCartDialog;
