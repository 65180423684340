import React, { Component } from "react";
import styled from "styled-components";
import Dialog from "./Dialog";
import * as Widget from "./Widget";
import CartOptionSpecItem from "./CartOptionSpecItem";

class CartOptionItem extends Component {
  render() {
    const { option, onSelect, onChangeFieldAmount } = this.props;

    return (
      <Wrapper>
        <div>
          <Widget.Checkbox
            style={{ marginBottom: 8, width: 120 }}
            iconStyle={{ fill: "#4a4a4a", marginRight: 4 }}
            labelStyle={{ color: "#000", fontWeight: 600, fontSize: 16 }}
            checked={option.isSelected}
            label={option.title}
            onCheck={() => onSelect()}
          />
        </div>
        <SpecWrapper isSelected={option.isSelected}>
          <CartOptionSpecItem
            title="大人"
            field="adult"
            onChange={value => onChangeFieldAmount("adult", value)}
            isSelected={option.isSelected}
            spec={option["adult"]}
          />
          <CartOptionSpecItem
            title="孩童佔床"
            field="childHasBed"
            onChange={value => onChangeFieldAmount("childHasBed", value)}
            isSelected={option.isSelected}
            spec={option["childHasBed"]}
          />
          <CartOptionSpecItem
            title="孩童不佔床"
            field="childNoBed"
            onChange={value => onChangeFieldAmount("childNoBed", value)}
            isSelected={option.isSelected}
            spec={option["childNoBed"]}
          />
          <CartOptionSpecItem
            title="孩童加床"
            field="childAddBed"
            onChange={value => onChangeFieldAmount("childAddBed", value)}
            isSelected={option.isSelected}
            spec={option["childAddBed"]}
          />
          <CartOptionSpecItem
            title="嬰兒"
            field="baby"
            onChange={value => onChangeFieldAmount("baby", value)}
            isSelected={option.isSelected}
            spec={option["baby"]}
          />
        </SpecWrapper>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  border-bottom: 1px solid #9b9b9b;
  padding: 12px 0;
`;

const SpecWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media screen and (max-width: 1023px) {
    flex-direction: column;
    display: ${props => (props.isSelected ? "flex" : "none")};
  }
`;

export default CartOptionItem;
