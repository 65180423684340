import React, { Component } from "react";
import styled from "styled-components";
import Dialog from "./Dialog";
import * as Widget from "./Widget";

class CartOptionSpecItem extends Component {
  render() {
    const { title, isSelected, spec, onChange } = this.props;

    return (
      <Wrapper>
        <p className="title">{title}</p>
        <div>
          <p className="price" style={{ color: this._getPriceColor() }}>
            {spec ? `$${spec.price.toLocaleString()}` : "- -"}
          </p>
          {spec && isSelected ? (
            <Widget.NumberInput
              min={0}
              value={spec.amount}
              onChange={value => onChange(value)}
            />
          ) : null}
        </div>
      </Wrapper>
    );
  }

  _getPriceColor = () => {
    const { field, spec } = this.props;

    if (!spec) {
      return "#444";
    }

    switch (field) {
      case "adult":
        return "#f2511b";

      case "childHasBed":
        return "#008c8d";

      case "childNoBed":
        return "#008c8d";

      case "childAddBed":
        return "#008c8d";

      case "baby":
        return "#008c8d";

      default:
        return "#444";
    }
  };
}

const Wrapper = styled.div`
  padding: 0 24px;
  flex: 1;
  @media screen and (max-width: 1023px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    padding: 4px 12px;
  }

  @media screen and (max-width: 479px) {
    padding: 4px 0;
  }

  & > .title {
    font-size: 14px;
    margin: 4px 0;
    color: #444;
    @media screen and (max-width: 1023px) {
      margin: 0;
    }
  }

  & > div {
    @media screen and (max-width: 1023px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
    }
    & > .price {
      font-size: 14px;
      font-weight: 700;
      margin: 4px 0;
      @media screen and (max-width: 1023px) {
        margin: 0 10px 0 0;
      }
    }
  }
`;

export default CartOptionSpecItem;
