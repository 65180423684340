import React, { Component } from "react";
import * as Widget from "./Widget";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 5px 10px;
  border-radius: 30px;
  background-color: #ccc;
  color: white;
  font-size: 14px;

  ${props => props.clickable && "cursor: pointer;"}

  ${props => props.css}
`;
class Label extends Component {
  render() {
    let { display = "", onClick = null, css = "" } = this.props;
    return (
      <Wrapper
        clickable={!!onClick}
        css={css}
        onClick={() => !!onClick && onClick()}
      >
        {display}
      </Wrapper>
    );
  }
}

export default Label;
