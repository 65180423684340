import React, { Component } from "react";
import styled from "styled-components";
import { formatPrice } from "../Utils/PriceFormatter";

class PriceDisplayer extends Component {
  render() {
    let { price } = this.props;
    return <Wrapper>NT {formatPrice(price)}</Wrapper>;
  }
}

const Wrapper = styled.div`
  font-size: 30px;
  font-weight: 600;
  color: #4e4e4e;
`;

export default PriceDisplayer;
