import React, { Component } from "react";
import styled from "styled-components";

let Wrapper = styled.div`
  width: 100%;

  & img {
    max-width: 100%;
    height: auto !important;
  }
  ${props => props.extraStyle}
`;

class CmsView extends Component {
  render() {
    let { cmsContent, extraStyle = "" } = this.props;

    return (
      <Wrapper
        dangerouslySetInnerHTML={{ __html: cmsContent }}
        className="cmsContent"
        extraStyle={extraStyle}
      />
    );
  }
}

export default CmsView;
