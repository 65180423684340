import React, { Component } from "react";
import Lightbox from "../Lightbox";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Image = styled.div`
  background-image: url(${props => props.img});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fdfdfd;
  width: 100%;
  height: 100%;
`;

class ProductImages extends Component {
  render() {
    let { images = [] } = this.props;
    if (images.length === 0) {
      return (
        <Wrapper>
          <Image img={""} />
        </Wrapper>
      );
    }
    return (
      <Wrapper>
        <Image img={images[0].src} />
        <Lightbox imagesArray={images} />
      </Wrapper>
    );
  }
}

export default ProductImages;
