import React, { Component } from "react";
import styled from "styled-components";

class DropdownSelector extends Component {
  render() {
    // options = [{display: 'one', value: 1}]
    let { value, title, options = [] } = this.props;
    return (
      <Wrapper>
        <label>{title}</label>
        <div className="select-wrapper">
          <select value={value} onChange={e => this._onSelect(e)}>
            {options.map((o, idx) => (
              <option key={idx} value={o.value}>
                {o.display}
              </option>
            ))}
          </select>
        </div>
      </Wrapper>
    );
  }

  _onSelect = e => {
    let { onSelect } = this.props;
    onSelect(e.target.value);
  };
}

const Wrapper = styled.div`
  & > label {
    color: grey;
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
  }

  & > .select-wrapper {
    height: 30px;
    max-width: 300px;
    & > select {
      width: 100%;
      height: 100%;
      outline: none;
      border-color: transparent;
    }
  }
`;

export default DropdownSelector;
