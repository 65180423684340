import React, { Component } from "react";
import Lightbox from "react-images";

class ImageLightbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lightboxIsOpen: false,
      currentImage: 0
    };
  }

  _openLightbox = (index = 0) => {
    this.setState({
      lightboxIsOpen: true,
      currentImage: index
    });
  };

  _closeLightbox = () => {
    this.setState({ lightboxIsOpen: false });
  };

  _gotoPrevious = () => {
    this.setState({ currentImage: this.state.currentImage - 1 });
    console.log("prev");
  };

  _gotoNext = () => {
    this.setState({ currentImage: this.state.currentImage + 1 });
    console.log("next");
  };

  _gotoImage = index => {
    this.setState({ currentImage: index });
  };

  render() {
    let {
      imagesArray,
      extraStyle,
      showThumbnail = true,
      centerImage = false
    } = this.props;

    return (
      <div style={extraStyle}>
        {imagesArray.length !== 0 ? (
          <div style={{ position: "relative" }}>
            {showThumbnail && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexWrap: "wrap",
                  justifyContent: centerImage ? "center" : "flex-start"
                }}
              >
                {imagesArray &&
                  imagesArray.map((image, idx) => {
                    return (
                      <img
                        key={idx}
                        // alt={image.name}
                        style={{ margin: "5px", cursor: "pointer" }}
                        height={50}
                        src={image.src}
                        onClick={this._openLightbox.bind(this, idx)}
                      />
                    );
                  })}
              </div>
            )}
          </div>
        ) : null}
        <Lightbox
          currentImage={this.state.currentImage}
          images={imagesArray}
          isOpen={this.state.lightboxIsOpen}
          onClickPrev={this._gotoPrevious}
          onClickNext={this._gotoNext}
          onClose={this._closeLightbox}
          showThumbnails={true}
          onClickThumbnail={this._gotoImage}
        />
      </div>
    );
  }
}

export default ImageLightbox;
