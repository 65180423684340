import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import Selectors from "../Selectors";
import ActionCreator from "../ActionCreator";
import * as Widget from "./Widget";
import ProductCommentList from "./ProductCommentList";
import CmsView from "./CmsView";
import ImageDisplayer from "./ImageDisplayer";
import Label from "./Label";
import JsonParser from "../Utils/JsonParser";
import DropDownSelector from "./DropdownSelector";
import PriceDisplayer from "./PriceDisplayer";
import TabHeader from "./TabHeader";

const TabWrapper = styled.div`
  & .tab-header {
  }
  & .tab-content {
  }
`;

const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px;

  h1 {
  }

  p {
    padding: 20px 0px;
    color: #4e4e4e;
  }
`;

const CustomButton = styled.button`
  padding: 10px;
  background-color: ${props => props.color || "white"};
  color: ${props => props.textColor || "black"};
`;

class ProductDetailContent extends React.Component {
  state = {
    quantity: 1
  };
  render() {
    let { item } = this.props;
    let { quantity } = this.state;
    if (!item) {
      return (
        <Widget.Row css="padding: 20px;" align="center" justify="center">
          <Widget.Spinner />
        </Widget.Row>
      );
    }
    return (
      <Widget.Row css={"display: flex; flex-wrap: wrap;"}>
        <ImageDisplayer
          images={item.images.map(image => ({ src: image.image, ...image }))}
        />
        <ItemInfo>
          <h1>{item.name}</h1>
          <p>{item.description}</p>
          <Widget.Row css={"padding: 50px 0px;"}>
            <PriceDisplayer price={item.price} />
          </Widget.Row>

          <DropDownSelector
            title="數量"
            value={quantity}
            options={[
              { value: 1, display: "1" },
              { value: 2, display: "2" },
              { value: 3, display: "3" }
            ]}
            onSelect={value => this.setState({ quantity: value })}
          />

          {/*
          <Widget.Row css={"padding: 50px 0px;"}>
            <CustomButton color={"#dd4b39"} textColor={"white"}>
              add to cart
            </CustomButton>
            <CustomButton>add to favorite list</CustomButton>
          </Widget.Row>
             */}
          <Widget.Row css={"padding: 20px 0px; flex-wrap: wrap;"}>
            {JsonParser(item.label, []).map(l => (
              <Label
                key={l}
                display={l}
                css="margin: 5px;"
                onClick={() => console.log("hi", l)}
              />
            ))}
          </Widget.Row>
        </ItemInfo>
      </Widget.Row>
    );
  }
}

const TAB_TYPES = {
  DETAIL: "DETAIL",
  INFO: "INFO",
  COMMENT: "COMMENT"
};

class ProductTabs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: TAB_TYPES.DETAIL
    };
  }

  render() {
    let { selectedTab } = this.state;
    let { item } = this.props;

    return (
      <TabWrapper>
        <div className="tab-header">
          <TabHeader
            activeTab={selectedTab}
            onTabClick={tab =>
              this.setState({
                selectedTab: tab
              })
            }
            tabs={[
              { display: "商品介紹", value: TAB_TYPES.DETAIL },
              { display: "商品資訊", value: TAB_TYPES.INFO },
              { display: "商品評價", value: TAB_TYPES.COMMENT }
            ]}
          />
        </div>
        <div className="tab-content">
          {(() => {
            if (!item) {
              return (
                <Widget.Row
                  justify="center"
                  align="center"
                  css="padding: 20px 10px;"
                >
                  <Widget.Spinner />
                </Widget.Row>
              );
            }
            switch (selectedTab) {
              case TAB_TYPES.DETAIL:
                return <CmsView cmsContent={item.content} />;
              case TAB_TYPES.INFO:
                return <CmsView cmsContent={item.info} />;
              case TAB_TYPES.COMMENT:
                return <ProductCommentList />;
            }
          })()}
        </div>
      </TabWrapper>
    );
  }
}

ProductDetailContent = connect(
  null,
  ActionCreator
)(ProductDetailContent);

export { ProductDetailContent, ProductTabs };
